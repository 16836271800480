<template>
    <div class="mt-lg-3 mt-1 pt-3 pb-0 px-3">
        <b-card class="vld-parent card-material" header="Profile" header-border-variant="light">
            <h5 slot="header" class="mb-0" style="border-bottom: none !important;">{{ $t('settings.profile') }}</h5>

            <strong>{{ $t('settings.general') }}</strong>
            <hr>

            <b-row class="pb-3">
                <b-col cols="6" sm="2">
                    <label>{{ $t('settings.myEmail') }}</label>
                </b-col>

                <b-col sm="4">
                    <label> {{userEmail}} </label>
                </b-col>
            </b-row>

            <update-password/>

            <b-row class="pb-3">
                <b-col cols="6" sm="2">
                    <label>{{ $t('settings.devicesAppearance') }}</label>
                </b-col>

                <b-col sm="4">
                    <b-form-radio-group id="devices-radio-group" v-model="selectedDeviceView">
                        <b-form-radio value="grid">{{ $t('settings.grid') }}</b-form-radio>
                        <b-form-radio value="list">{{ $t('settings.list') }}</b-form-radio>
                    </b-form-radio-group>
                </b-col>
            </b-row>

            <b-row class="pb-3">
                <b-col cols="6" sm="2">
                    <label>{{ $t('settings.language') }}</label>
                </b-col>

                <b-col sm="4">
                    <b-form-radio-group id="language-radio-group" v-model="selectedLanguage">
                        <b-form-radio value="en">{{ $t('settings.english') }}</b-form-radio>
                        <b-form-radio value="it">{{ $t('settings.italian') }}</b-form-radio>
                    </b-form-radio-group>
                </b-col>
            </b-row>

            <notifications/>

            <div v-if="showingBottomNav">
                <strong>{{ $t('settings.other') }}</strong>
                <hr>
                <b-btn class="mt-1" variant="primary" @click="logout">{{ $t('settings.logout') }}</b-btn>
            </div>
        </b-card>

        <InfoModal></InfoModal>

        <ErrorModal></ErrorModal>
    </div>
</template>

<script>
    import store from '@/store';
    import {mapGetters, mapActions} from 'vuex';
    import UpdatePassword from "@/components/settings/UpdatePassword";
    import Notifications from "@/components/settings/Notifications";
    import InfoModal from "@/components/general-purpose-modals/InfoModal";
    import ErrorModal from "@/components/general-purpose-modals/ErrorModal";
    import {isMobileNavigationMode} from "@/utilities/mobile.navigation.utility";

    export default {
        name: "Settings",

        components: {
            UpdatePassword,
            Notifications,
            InfoModal,
            ErrorModal
        },

        computed: {
            ...mapGetters({
                userEmail: 'user/email',
                getDevicesView: 'user/devicesView',
                getLanguage: 'user/language'
            }),

            showingBottomNav: function () {
                return isMobileNavigationMode(this.$platform);
            },

            selectedDeviceView: {
                get: function () {
                    return this.getDevicesView;
                },

                set: async function (value) {
                    try {
                        await this.$axios.post('/update-devices-view', {
                            selectedDeviceView: value
                        });

                        store.commit('user/setDevicesView', value);
                    } catch (e) {
                        console.log(e);
                    }
                }
            },

            selectedLanguage: {
                get: function () {
                    return this.getLanguage;
                },

                set: async function (value) {
                    try {
                        await this.$axios.post('/update-language', {
                            language: value
                        });

                        store.commit('user/setLanguage', value);
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },

        async beforeRouteEnter(to, from, next) {
            await store.dispatch('user/fetchUserSettings');
            next();
        },

        methods: {
            ...mapActions('auth', [
                'logout'
            ])
        }
    }
</script>

<style scoped>

</style>


































