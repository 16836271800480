<template>
    <div>
        <b-row class="pb-3">
            <b-col cols="6" sm="2">
                <label>Password</label>
            </b-col>

            <b-col sm="4">
                <b-button v-b-modal.update-password-modal variant="primary" size="sm">{{ $t('settings.update') }}</b-button>
            </b-col>
        </b-row>

        <b-modal id="update-password-modal" header-bg-variant="info" :title="$t('settings.updatePassword')" centered :ok-title="$t('settings.update')"
                 :cancel-title="$t('settings.cancel')" @ok="updatePassword()" @hidden="cleanPasswordFields">
            <b-row class="mb-3">
                <b-col cols="6" sm="4">
                    <label>{{ $t('settings.enterOldPassword') }}</label>
                </b-col>

                <b-col cols="10" sm="7">
                    <input type="password" autocomplete="off" class="form-control input-sm" id="old-password" name="old-password"
                           v-model="oldPassword" @click="$root.$emit('hideBottomNav')">
                </b-col>
            </b-row>

            <b-row class="mb-3">
                <b-col cols="6" sm="4">
                    <label>{{ $t('settings.enterNewPassword') }}</label>
                </b-col>

                <b-col cols="10" sm="7">
                    <input type="password" autocomplete="off" class="form-control input-sm" id="new-password" name="new-password"
                           v-model="newPassword" @click="$root.$emit('hideBottomNav')">
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" sm="4">
                    <label>{{ $t('settings.reenterNewPassword') }}</label>
                </b-col>

                <b-col cols="10" sm="7">
                    <input type="password" autocomplete="off" class="form-control input-sm" id="reenter-password" name="reenter-password"
                           v-model="reenterPassword" @click="$root.$emit('hideBottomNav')">
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "UpdatePassword",

        data() {
            return {
                oldPassword: "",
                newPassword: "",
                reenterPassword: ""
            }
        },

        methods: {
            updatePassword() {
                this.$axios({
                    method: 'put',
                    url: '/change-password',
                    data: {
                        'oldPassword': this.oldPassword,
                        'newPassword': this.newPassword,
                        'reenterPassword': this.reenterPassword
                    },
                    timeout: 10000
                }).then(response => {
                    this.$root.$emit('showInfo', "Your password was changed successfully");
                    this.$root.$emit('bv::show::modal', 'info-modal');
                }).catch((err) => {
                    let error = err.response.data;

                    if (error.type) {
                        if (error.type === "ValidationError" || error.type === "DemoAccountError") {
                            this.$root.$emit('showError', error.body);
                        }
                    } else {
                        this.$root.$emit('showError', "Could not update password.");
                    }

                    this.$root.$emit('bv::show::modal', 'error-modal');
                });
            },

            cleanPasswordFields() {
                this.oldPassword = "";
                this.newPassword = "";
                this.reenterPassword = "";
            }
        }
    }
</script>

<style scoped>

</style>