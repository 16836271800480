<template>
    <div>
        <br><strong>{{ $t('settings.notifications') }}</strong>
        <hr>

        <b-row v-if="$platform === 'web'">
            <b-col cols="12">
                <b-form-checkbox v-model="webNotifications" name="web-notifications" switch :disabled="webNotificationsDisabled()"
                                 @change="updateWebNotifications">
                    {{ $t('settings.webNotifications') }}
                </b-form-checkbox>
            </b-col>
            <b-col class="mt-2">
                <span v-if="webNotificationsDisabled()" style="color: gray;" v-html="$t('settings.webNotificationsDisabled')"/>
                <span v-else style="color: gray;" v-html="$t('settings.webNotificationsDesc')"/>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                <b-form-checkbox v-model="mobileNotifications" name="mobile-notifications" switch :disabled="mobileNotificationsDisabled()"
                                 @change="updateMobileNotifications">
                    {{ $t('settings.pushNotifications') }}
                </b-form-checkbox>
            </b-col>
            <b-col class="mt-2">
                <span v-if="mobileNotificationsDisabled()" style="color: gray;" v-html="$t('settings.pushNotificationsDisabled')"/>
                <span v-else style="color: gray;" v-html="$t('settings.pushNotificationsDesc')"/>
            </b-col>
        </b-row>

        <b-row class="mt-3 mb-2">
            <b-col cols="12">
                <b-form-checkbox v-model="emailNotifications" name="email-notifications" switch @change="updateEmailNotifications">
                    {{ $t('settings.emailNotifications') }}
                </b-form-checkbox>
            </b-col>
            <b-col class="mt-2">
                <span style="color: gray;">
                    {{ $t('settings.emailNotificationsDesc') }}
                </span>
            </b-col>
        </b-row>

        <b-row class="mt-3 mb-2">
            <b-col cols="12">
                <b-form-checkbox v-model="receiveMaintenanceEmail" name="receive-maintenance-email" switch @change="updateReceiveMaintenanceEmail">
                    {{ $t('settings.maintenanceEmail') }}
                </b-form-checkbox>
            </b-col>
            <b-col class="mt-2">
                <span style="color: gray;">
                    {{ $t('settings.maintenanceEmailDesc') }}
                </span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import store from '@/store';
    import {mapGetters} from 'vuex';
    import PushNotificationsService from "@/services/push.notifications.service";
    import {Plugins} from '@capacitor/core';

    const {LocalNotifications, App} = Plugins;

    export default {
        name: "Notifications",

        async beforeCreate() {
            if (this.$platform !== 'web') {
                let enabled = await LocalNotifications.areEnabled();
                this.pushNotifications = enabled.value;

                //in case user went to phone settings and enabled/disabled push notifications
                App.addListener('appStateChange', async (state) => {
                    if (state.isActive) {
                        let enabled = await LocalNotifications.areEnabled();
                        this.pushNotifications = enabled.value;
                    }
                });
            }
        },

        data() {
            return {
                pushNotifications: false
            }
        },

        computed: {
            ...mapGetters({
                getReceiveMaintenanceEmail: 'user/receiveMaintenanceEmail',
                getWebNotifications: 'user/webNotifications',
                getMobileNotifications: 'user/mobileNotifications',
                getEmailNotifications: 'user/emailNotifications'
            }),

            receiveMaintenanceEmail: {
                get: function () {
                    return !!this.getReceiveMaintenanceEmail;
                },

                set: function (value) {
                }
            },

            webNotifications: {
                get: function () {
                    return !!this.getWebNotifications;
                },

                set: function (value) {
                    if (value && Notification.permission === 'default') {
                        localStorage.setItem('asked_for_web_notifications', "false");
                        PushNotificationsService.requestPermission();
                    }
                }
            },

            mobileNotifications: {
                get: function () {
                    return !!this.getMobileNotifications;
                },

                set: function (value) {
                }
            },

            emailNotifications: {
                get: function () {
                    return this.getEmailNotifications === 1;
                },

                set: function (value) {
                }
            }
        },

        methods: {
            webNotificationsDisabled() {
                return this.$platform !== 'web' || Notification.permission === 'denied';
            },

            mobileNotificationsDisabled() {
                return this.$platform === 'web' || !this.pushNotifications;
            },

            updateWebNotifications(value) {
                this.$axios({
                    method: 'post',
                    url: 'update-web-notifications',
                    data: {
                        notifications: value
                    }
                }).then(() => {
                    store.commit('user/setWebNotifications', value);
                });
            },

            updateMobileNotifications(value) {
                this.$axios({
                    method: 'post',
                    url: 'update-mobile-notifications',
                    data: {
                        notifications: value
                    }
                }).then(() => {
                    store.commit('user/setMobileNotifications', value);
                });
            },

            updateEmailNotifications(value) {
                this.$axios({
                    method: 'post',
                    url: 'update-email-notifications',
                    data: {
                        notifications: value
                    }
                }).then(() => {
                    store.commit('user/setEmailNotifications', value);
                });
            },

            updateReceiveMaintenanceEmail(value) {
                this.$axios({
                    method: 'post',
                    url: '/update-receive-maintenance-email',
                    data: {
                        'receiveMaintenanceEmail': value
                    },
                }).then(() => {
                    store.commit('user/setReceiveMaintenanceEmail', value);
                });
            }
        }
    }
</script>

<style scoped>

</style>